<template>
    <div class="base-container">
        <div class="main-wrapper">
             <div class="search_box">
                <div class="tab-item">本项目建设方法律纠纷 {{ total }}</div>
                <div class="search">
                    <div class="left-search">
                        <el-input class="search-content" size="medium" placeholder="输入任意内容关键字进行查询" v-model="keyStr"></el-input>
                        <el-button type="primary" size="small" @click="SearchLegalDisputes">查询</el-button>
                    </div>
                </div>
            </div>
            
            <div class='filter-box'>
                <el-radio-group v-model="checkBox">
                    <el-radio-button  v-for="city in Options" :label="city.type" :key="city.type">{{ city.type }} {{city.count}}</el-radio-button>
                </el-radio-group>
            </div>
            <div class="disputes-info">
                <ul>
                    <li class="disputes-main" v-for="(item,index) in projectList" :key="index">
                        <div class="title" v-text="item.caseName.replace(/<[^>]+>/g, '')" :title="item.caseName.replace(/<[^>]+>/g, '')" @click="goCaseDetails(item)"></div>
                        <div class="item1">
                            <div class="info-item1">
                                <div>建设单位：</div>
                                <div v-text="item.jsdw || taxpayerName || '——'"></div>
                            </div>
                            <div class="info-item1">
                                <div>原告：</div>
                                <div v-text="item.plainfiffName || '——'"></div>
                            </div>
                            <div class="info-item1">
                                <div>案由：</div>
                                <div v-text="item.caseReason || '——'"></div>
                            </div>
                            <div class="info-item1">
                                <div>法院名称：</div>
                                <div v-text="item.court || '——'">-</div>
                            </div>
                        </div>
                        <div class="item2">
                            <div class="info-item2">
                                <div>施工单位：</div>
                                <div v-text="item.sgdw  || '——'">-</div>
                            </div>
                            <div class="info-item2">
                                <div>被告：</div>
                                <div v-text="item.defendantName  || '——'" :title="item.defendantName"></div>
                            </div>
                            <div class="info-item2">
                                <div>案号：</div>
                                <div v-text="item.caseNo  || '——'">-</div>
                            </div>
                            <div class="info-item2">
                                <div>日期：</div>
                                <div v-text="item.judgeDate  || '——'">-</div>
                            </div>
                        </div>
                        <div class="view-all" @click="goCaseDetails(item)">[查看全部]</div>
                        <div class="break_line"></div>
                        <div class="award-details">
                            <div class="award-title">裁定书详情</div>
                            <div class="award-info">
                                <div class="brief-Introduction" v-text="item.caseIntroduction"></div>
                            </div>
                        </div>
                    </li>
                </ul>
                <el-pagination
                    class="disputes-page"
                    background
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size=pageSize
                    layout="total, prev, pager, next, jumper"
                    :total=total>
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {getStore, setStore,getLocalStore} from '@/util/store'
import {mapMutations} from 'vuex'
import {Loading} from 'element-ui';

export default {
    name: "tabOther",
    filters: {},
    data() {
        return {
            activeName: "1",
            keyStr: "",
            editableTabs: [{}],
            projectList: [],
            total: 0,
            currentPage: 1,
            pageSize: 10,
            checkBox: '全部',
            Options: [],
            taxpayerName: getStore('thisProject').taxpayerName,
        }
    },
    created(){
        this.getReport(0,'','')
    },
    destroyed(){
        this.getReport(1,'','')
    },
    mounted() {
        this.loadLegalRisk()
    },
    watch: {
        checkBox(newName, oldName) {
            this.getReport(2,'list','tagBtn');
            this.currentPage = 1;
            this.loadLegalRisk()
        }
    },
    methods: {
        ...mapMutations(["addPath", "addMenuList"]),
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'tabBuild',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        SearchLegalDisputes() {
            this.getReport(2,'list','searchBtn');
            this.currentPage = 1;
            this.loadLegalRisk()
        },
   
        handleClick(tab, event) {

        },
        handleCurrentChange(val) {
            this.getReport(2,'list',val);
            this.currentPage = val
            this.loadLegalRisk()
        },
        goCaseDetails(data) {
            this.getReport(2,'list','listItem');
            this.addPath("案件详情");
            this.addMenuList("/viewAll");
            setStore("currentMenu", "/viewAll");
            setStore("navitem", "viewAll");
            this.$router.push({path: "/viewAll"});
            setStore('CaseDetails', data);
        },
        loadLegalRisk() {
            let projectId = getStore('thisProject').id;
            let userId = getLocalStore('userinfo').id;
            let loading_loadLegalRisk = Loading.service({background: 'rgba(0, 0, 0, 0.5)'});
            this.axios.request({
                loading: true,
                method: "post",
                url: '/dms/getBuildingProjectRisk',
                 
                data: {
                    field: '',
                    order: '',
                    keyStr: this.keyStr,
                    flag: this.checkBox || '全部',
                    projectId: projectId,
                    userId: userId,
                    projectName:getStore('thisProject').proName,
                    companyName:getStore('thisProject').taxpayerName,
                    pageNum: this.currentPage,
                    pageSize: this.pageSize,
                }
                // data: {companyName:'秦皇岛世纪港湾房地产开发有限公司',xmid:'90006349'},
            }).then((res) => {
                if (res.data.status == "failed") {
                    if(res.data.msg=='tokenMissing'||res.data.msg=='tokenExpired'||res.data.msg=='tokenIllegal'){
                       
                    }else{
                        this.$message.error(res.data.msg);
                    }
                } else if (res.data.status == "success") {
                    setStore('legalRisk', res.data.data);
                    this.projectList = res.data.data.list;
                    loading_loadLegalRisk.close();
                    if (this.Options == '') {
                        this.Options = res.data.data.docType;
                    }
                    this.total = res.data.data.count;
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
@import "../../assets/css/legal-risk.less";

</style>